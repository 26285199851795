<template>
  <div class="container mt-3">
    <div class="row">
      <h2>{{ $t('cert.certDetail') }} / {{ cert.name }}</h2>
    </div>
    <div class="row">
      <router-link
        class="arrow-back"
        :to="{ name: 'Dashboard' }"
        title="Zpět na nástěnku"
      >
        <i class="bi bi-arrow-left"></i>{{ $t('general.backToDashboard') }}</router-link
      >
    </div>
    <div class="row mt-4">
      <div class="col-12 col-md-6 mb-3">
        <div class="card">
          <div class="card-header d-flex">
            {{ $t('cert.certInfo') }}
            <div class="ms-auto">
              <button class="delete-button" @click="deleteCert()">
                {{ $t('cert.deleteCert') }}
              </button>
            </div>
          </div>
          <div class="card-body">
            <Skeletor v-if="loading" />
            <div v-if="!loading">
              <div class="row">
                <div class="col-6">{{ $t('cert.certName') }}:</div>
                <div class="col-6">{{ cert.name }}</div>
              </div>
              <div class="row">
                <div class="col-6">{{ $t('cert.certCreatedDate') }}:</div>
                <div class="col-6">{{ formatDate(cert.created_at) }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <CertDownloadComponent></CertDownloadComponent>
        <div v-if="cert.file_uuid" class="card mt-3">
          <div class="card-header d-flex">
            {{ $t('file.file') }}
          </div>
          <div class="card-body">
            <Skeletor v-if="loading" />
            <div v-if="!loading">
              <div class="row">
                <div class="col-12">
                  <router-link
                    class="btn btn-primary w-100"
                    :to="{ name: 'FileDetail', params: { uuid: cert.file_uuid } }"
                    title="Zobrazení souboru"
                    >{{ $t('general.show') }}</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/http';

export default {
  mounted() {
    this.getCertDetail();
  },

  data() {
    return {
      cert: [],
      loading: true,
    };
  },

  methods: {
    getCertDetail() {
      let self = this;
      http.get('/certs/' + self.$route.params.uuid + '/detail').then((response) => {
        self.cert = response.data.cert;
        self.loading = false;
      });
    },

    deleteCert() {
      let self = this;

      let deleteFile = false;

      self.$swal
        .fire({
          title: self.$t('cert.confirmDelete'),
          icon: 'warning',
          input: self.cert?.file_uuid ? 'checkbox' : false,
          inputValue: 1,
          inputPlaceholder: self.$t('cert.fileDeleteToo'),
          showCancelButton: true,
          confirmButtonColor: '#175cff',
          cancelButtonColor: '#d33',
          confirmButtonText: self.$t('general.yes'),
          cancelButtonText: self.$t('general.no'),
        })
        .then((result) => {
          if (result.isConfirmed) {
            deleteFile = !result.value;

            http
              .post(
                '/certs/' +
                  self.$route.params.uuid + '/delete?deleteOnlyCert=' +
                  deleteFile
              )
              .then((response) => {
                self.$router.push({ name: 'Dashboard' });
              });
          }
        });
    },

    getPdfSrc() {
      /* this.pdfSrc = 'data:application/pdf;base64,' + this.cert.pdf_base64; */
    },

    formatDate(date) {
      let d = new Date(date);
      return (
        ('00' + d.getDate()).slice(-2) +
        '. ' +
        ('00' + (d.getMonth() + 1)).slice(-2) +
        '. ' +
        d.getFullYear() +
        ' ' +
        ('00' + d.getHours()).slice(-2) +
        ':' +
        ('00' + d.getMinutes()).slice(-2) +
        ':' +
        ('00' + d.getSeconds()).slice(-2)
      );
    },
  },
};
</script>
