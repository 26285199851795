<template>
    <div class="container mt-3">
        <div class="row">
            <h2>{{ $t('file.fileDetail') }} / {{ file.name }}</h2>
        </div>
        <div class="row">
            <router-link
                class="arrow-back"
                :to="{ name: 'Dashboard' }"
                title="Zpět na nástěnku"
            >
                <i class="bi bi-arrow-left"></i>{{ $t('general.backToDashboard') }}
            </router-link
            >
        </div>
        <div class="row mt-4">
            <div class="col-12 col-md-6 mb-3">
                <div class="card">
                    <div class="card-header d-flex">
                        {{ $t('file.fileInfo') }}
                        <div class="ms-auto">
                            <button class="delete-button" @click="deleteFile()">
                                {{ $t('file.deleteFile') }}
                            </button>
                        </div>
                    </div>
                    <div class="card-body">
                        <Skeletor v-if="loading"/>
                        <div v-if="!loading">
                            <div class="row">
                                <div class="col-6">{{ $t('file.fileName') }}:</div>
                                <div class="col-6">{{ file.name }}</div>
                            </div>
                            <div class="row">
                                <div class="col-6">{{ $t('cert.certName') }}:</div>
                                <div class="col-6">{{ file.cert?.name }}</div>
                            </div>
                            <div class="row">
                                <div class="col-6">{{ $t('file.folder') }}:</div>
                                <div class="col-6">{{ file.folder_name }}</div>
                            </div>
                            <div class="row">
                                <div class="col-6">{{ $t('file.fileCreatedDate') }}:</div>
                                <div class="col-6">{{ formatDate(file.created_at) }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 col-md-6 mb-3">
                <div class="card">
                    <div class="card-header">{{ $t('cert.cert') }}</div>
                    <div class="card-body">
                        <Skeletor v-if="loading"/>
                        <div v-if="!loading">
                            <div class="row mb-3" v-if="file && file.cert">
                                <div class="col-6">{{ $t('cert.certWasGenerated') }}:</div>
                                <div class="col-6">{{ formatDate(file?.cert.created_at) }}</div>
                            </div>
                            <div class="row">
                                <div class="col-6 ms-auto" v-if="!file.cert_id">
                                    <button @click="generateCert()" class="btn btn-primary w-100" :disabled="generatingCert">
                                        {{ generatingCert ? $t('cert.generatingCert') : $t('cert.generateCert') }}
                                    </button>
                                </div>
                                <div class="d-flex d-inline align-items-center" v-if="file.cert_id">
                                    <div class="col-6 ms-auto">
                                        <button @click="deleteCert()" class="delete-button w-100">
                                            {{ $t('cert.deleteCert') }}
                                        </button>
                                    </div>
                                    <div class="col-6 ms-auto">
                                        <router-link
                                            class="btn btn-primary w-100"
                                            :to="{ name: 'CertDetail', params: { uuid: file.cert.uuid } }"
                                            title="{{ $t('cert.showCert') }}"
                                        >{{ $t('cert.showCert') }}
                                        </router-link
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-4 mb-5">
            <div class="col-12">
                <div class="card">
                    <div class="card-header d-flex">
                        {{ $t('file.filePreview') }}
                        <div class="ms-auto">
                            <button class="btn btn-primary" @click="downloadFile()">
                                {{ $t('file.download') }}
                            </button>
                        </div>
                    </div>
                    <div class="card-body">
                        <Skeletor v-if="loading"/>
                        <div v-if="!loading">
                            <div v-if="file && file.mime_type == 'application/pdf'">
                                <vue-pdf-embed :source="pdfSrc"/>
                            </div>
                            <div
                                v-else-if="file && file.mime_type && file.mime_type.includes('image/')"
                            >
                                <img
                                    v-bind:src="'data:image/jpeg;base64,' + file.base64"
                                    class="w-100"
                                    alt=""
                                />
                            </div>
                            <div
                                v-else-if="
                  file &&
                  file.mime_type ==
                    ('application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                      'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
                      'application/vnd.ms-powerpoint' ||
                      'application/vnd.ms-excel' ||
                      'application/msword')
                "
                            >
                                <!-- <VueDocPreview :value="file.view_url" type="office" /> -->

                                <div class="o365-iframe-wrapp">
                                    <iframe
                                        :src="
                      'https://view.officeapps.live.com/op/embed.aspx?src=' +
                      file?.view_url
                    "
                                        frameborder="0"
                                        width="100%"
                                        height="100%"
                                        class="o365-iframe"
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import http from '@/http';
import VuePdfEmbed from 'vue-pdf-embed';

export default {
    components: {
        VuePdfEmbed,
    },

    mounted() {
        this.getFileDetail();
    },

    data() {
        return {
            file: [],
            loading: true,
            generatingCert: false,
        };
    },

    computed: {
        pdfSrc() {
            if (this.file && this.file.mime_type == 'application/pdf' && this.file.base64) {
                return {
                    data: window.atob(this.file.base64),
                };
            }
            return null;
        },
    },

    methods: {
        getFileDetail() {
            let self = this;
            http.get('/files/' + self.$route.params.uuid + '/detail').then((response) => {
                self.file = response.data.file;
                self.loading = false;
            });
        },

        generateCert() {
            let self = this;
            self.generatingCert = true;
            let body = {
                file_model: this.file,
            };

            http.post('/certs/generate', body).then((response) => {
                self.file = response.data.file;
                self.generatingCert = false;
            });
        },

        deleteCert() {
            let self = this;

            let deleteFile = false;

            self.$swal
                .fire({
                    title: self.$t('cert.confirmDelete'),
                    icon: 'warning',
                    input: self.file?.cert_uuid ? 'checkbox' : false,
                    inputValue: 1,
                    inputPlaceholder: self.$t('cert.fileDeleteToo'),
                    showCancelButton: true,
                    confirmButtonColor: '#175cff;',
                    cancelButtonColor: '#d33',
                    confirmButtonText: self.$t('general.yes'),
                    cancelButtonText: self.$t('general.no'),
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        deleteFile = !result.value;

                        http
                            .post(
                                '/certs/' + self.file.cert_uuid + '/delete?deleteOnlyCert=' + deleteFile
                            )
                            .then((response) => {
                                if (!deleteFile) {
                                    self.$router.push({name: 'Dashboard'});
                                } else {
                                    self.file.cert_id = null;
                                    self.file.cert_uuid = null;
                                    self.file.cert = null;
                                }
                            });
                    }
                });
        },

        deleteFile() {
            let self = this;

            let deleteCert = false;

            self.$swal
                .fire({
                    title: self.$t('file.confirmDelete'),
                    icon: 'warning',
                    input: self.file?.cert_uuid ? 'checkbox' : false,
                    inputValue: 1,
                    inputPlaceholder: self.$t('file.certDeleteToo'),
                    showCancelButton: true,
                    confirmButtonColor: '#175cff;',
                    cancelButtonColor: '#d33',
                    confirmButtonText: self.$t('general.yes'),
                    cancelButtonText: self.$t('general.no'),
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        deleteCert = !result.value;

                        http
                            .post(
                                '/files/' +
                                self.$route.params.uuid +
                                '/delete?deleteOnlyFile=' +
                                deleteCert
                            )
                            .then((response) => {
                                self.$router.push({name: 'Dashboard'});
                            });
                    }
                });
        },

        downloadFile() {
            let self = this;
            http
                .get('/files/' + self.$route.params.uuid + '/view?download=true', {
                    responseType: 'blob',
                })
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));

                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', self.file.filename); // Nastav název a příponu souboru

                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                });
        },

        formatDate(date) {
            let d = new Date(date);
            return (
                ('00' + d.getDate()).slice(-2) +
                '. ' +
                ('00' + (d.getMonth() + 1)).slice(-2) +
                '. ' +
                d.getFullYear() +
                ' ' +
                ('00' + d.getHours()).slice(-2) +
                ':' +
                ('00' + d.getMinutes()).slice(-2) +
                ':' +
                ('00' + d.getSeconds()).slice(-2)
            );
        },
    },
};
</script>

<style>
.o365-iframe-wrapp {
    position: relative;
}

.o365-iframe-wrapp:after {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 40px;
    width: 100%;
    background: #fff;
    display: block !important;
    content: '';
}
</style>
