<template>
  <div class="card">
    <div class="card-header">{{ $t('cert.certDownload') }}</div>
    <div class="card-body">
      <div class="row"><div class="col-6">
        <router-link
                class="btn btn-primary w-100"
                :to="{ name: 'CertPdfDetail', params: { uuid: $route.params.uuid } }"
                title="Zobrazení certifikátu"
                >{{ $t('general.show')}}</router-link></div><div class="col-6">
        <button @click="downloadCert()" class="btn btn-primary w-100">{{ $t('general.download')}}</button></div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/http';

export default {
  mounted() {},

  data() {
    return {};
  },

  methods: {
    downloadCert() {
      let self = this;
      http
        .post('/certs/' + self.$route.params.uuid + '/download', {
          responseType: 'blob',
        })
        .then((response) => {
          var fileURL = `data:application/pdf;base64,${response.data}`;
          var fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'Certificate.pdf');
          document.body.appendChild(fileLink);

          fileLink.click();
          fileLink.remove();
        });
    },
  },
};
</script>
